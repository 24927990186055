import styled from '@emotion/styled'
import MuiButton from '@mui/material/Button'

export const Button = styled(MuiButton)`
    text-transform: none;
`

export const ErrorMessage = styled.div`
    color: #ff1816;
    text-transform: capitalize;
    margin: 10px 0;
`
