import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
// Hooks
import useFetch from 'hooks/useFetch'
// Components
import Dialog from 'atoms/common/Dialog'
import TagGroup from 'molecules/TagGroup'
// Helpers
import { displayNotification } from 'helpers/common/notifications'
// Types
import { TagGroupData, TagGroupsPayload } from 'types/account.d'
import { RCTError } from 'types/api.d'
import { TagSettingsProps } from './TagSettings.d'

import { tagColors } from 'helpers/tag/tag'
// Styles
import * as Styled from './TagSettings.styled'

export default function TagSettings({ tagGroups, onFinishTagSettings, hasUnsavedChanges = false }: TagSettingsProps) {
    let userTagGroups = tagGroups ? [...tagGroups] : []

    const [tagsDialogOpened, setTagsDialogOpened] = useState(false)
    const [warningDialogOpened, setWarningDialogOpened] = useState(false)
    const [error, setError] = useState<RCTError>()

    const { accountId } = useParams()
    const fetchData = useFetch()

    useEffect(() => {
        // Reset tags dialog data when it is closed
        if (!tagsDialogOpened) {
            setError(undefined)
        }
    }, [tagsDialogOpened])

    const onTagSettingsBtnClicked = () => {
        if (hasUnsavedChanges) {
            setWarningDialogOpened(true)
        } else {
            setTagsDialogOpened(true)
        }
    }

    const onCloseWarningDialog = () => {
        setWarningDialogOpened(false)
    }

    const onOpenTagsDialog = () => {
        setWarningDialogOpened(false)
        setTagsDialogOpened(true)
    }

    const closeTagsDialog = () => {
        setTagsDialogOpened(false)
    }

    const onSaveSettings = () => {
        const tagsPayload: TagGroupsPayload = { tagGroups: userTagGroups.filter(Boolean) }

        fetchData({ url: `/account/${accountId}/tag-group`, method: 'POST', data: tagsPayload })
            .then(() => {
                displayNotification('tag settings have been saved successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000,
                })
                closeTagsDialog()
                onFinishTagSettings()
            })
            .catch((error) => setError(error.response?.data?.error || error))
    }

    const updateTagGroup = (index: number, tagGroupData?: TagGroupData) => {
        if (tagGroupData?.name) {
            userTagGroups[index] = tagGroupData
        } else {
            delete userTagGroups[index]
        }
    }

    return (
        <div>
            <Styled.Button variant="contained" onClick={onTagSettingsBtnClicked}>
                Tag Settings
            </Styled.Button>

            <Dialog
                open={warningDialogOpened}
                dialogTitle="⚠️ Warning"
                acceptButton={{ label: 'Continue anyway', onClick: onOpenTagsDialog }}
                declineButton={{ label: 'Cancel', onClick: onCloseWarningDialog }}
            >
                <p>By editing the tag settings, all the unsaved changes will be lost.</p>
                <p>Would you like to continue?</p>
            </Dialog>

            <Dialog
                open={tagsDialogOpened}
                dialogTitle="Tag Settings"
                acceptButton={{ label: 'Save', onClick: onSaveSettings }}
                declineButton={{ label: 'Cancel', onClick: closeTagsDialog }}
            >
                {tagColors.map((color, index) => (
                    <TagGroup
                        key={color}
                        color={color}
                        tagGroup={userTagGroups[index]}
                        onUpdateTagGroup={(tagGroupData) => updateTagGroup(index, tagGroupData)}
                    />
                ))}
                {error && <Styled.ErrorMessage>{`Error ${error?.code}: ${error?.message}`}</Styled.ErrorMessage>}
            </Dialog>
        </div>
    )
}
