import { createTheme } from '@mui/material/styles'

//module augmenetation
declare module '@mui/material/styles' {
    interface Palette {
        palette?: {
            primary?: {
                main?: string
                dark?: string
                light?: string
            }
        }
    }
}

const amadeusBaseTheme = createTheme({
    palette: {
        primary: {
            main: '#005eb8',
            dark: '#004485',
            light: '#9bcaeb',
        },
    },

    components: {
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                    minWidth: '30px',
                    height: '30px',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: '0.9em',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    padding: '14px 12px',
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                },
            },
        },
    },
})
export default amadeusBaseTheme
