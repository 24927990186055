import styled from '@emotion/styled'
import { RadioGroup as MuiRadioGroup } from '@mui/material'
import { CheckboxLabelProps } from './BulkEdit.d'

export const BulkEditContent = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 5% 20% 30%;
    grid-template-rows: repeat(3, 90px);
    align-items: center;
`

export const RadioGroup = styled(MuiRadioGroup)`
    justify-content: space-between;
`

export const CheckboxLabel = styled.label<CheckboxLabelProps>`
    color: ${({ disabled }) => disabled && 'rgba(0, 0, 0, 0.38)'};
`
