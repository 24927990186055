import React from 'react'
// Helpers
import { tagColors } from 'helpers/tag/tag'
// Types
import { TagContextData, TagProviderProps, TagsLookup } from './TagContext.d'

const TagContext = React.createContext<TagContextData>({})

const TagProvider = ({ tagGroupsData, children }: TagProviderProps) => {
    const tagsLookup: TagsLookup = {}

    tagGroupsData?.forEach((group, groupIndex) => {
        group.tags.forEach((tag) => {
            tagsLookup[tag.id!] = {
                tagName: tag.name,
                displayName: `${group.name} - ${tag.name}`,
                color: tagColors[groupIndex],
                groupData: group,
                groupIndex,
            }
        })
    })

    return <TagContext.Provider value={{ tagsLookup }}>{children}</TagContext.Provider>
}

export { TagContext, TagProvider }
