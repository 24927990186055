import React, { FC } from 'react'
import { ReactComponent as ActiveSVG } from 'assets/icons/active.svg'
import { ReactComponent as InactiveSVG } from 'assets/icons/inactive.svg'
import { ActivationStatusProps } from './ActivationStatus.d'
import * as Styled from './ActivationStatus.styled'

const ActivationStatus: FC<ActivationStatusProps> = ({ status }) => {
    return (
        <Styled.ActivationStatusWrapper>
            {status === 'active' ? (
                <ActiveSVG data-testid="active-icon" />
            ) : (
                <InactiveSVG data-testid="inactive-icon" />
            )}
            <span>{status}</span>
        </Styled.ActivationStatusWrapper>
    )
}

export default ActivationStatus
