import styled from '@emotion/styled'
import Pagination from '@mui/material/Pagination'

export const PaginationWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`

export const PaginationSection = styled(Pagination)`
    flex-basis: 85%;

    ul {
        justify-content: center;
        width: 100%;
    }
`

export const RowSelectionSection = styled.div`
    flex-basis: 15%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.8rem;

    span {
        padding-right: 8px;
    }

    select {
        padding-right: 22px;
        font-size: 0.8rem;
        background: none;
    }

    .MuiNativeSelect-root:before,
    .MuiNativeSelect-root:after {
        border-bottom: none;
        content: none;
    }

    .MuiInputBase-root {
        border-bottom: none;
        background-color: white;
    }
`
