import React, { FC, useMemo } from 'react'
//Components
import { Popover } from '@mui/material'
import PropertyTaggingSelection from 'organisms/PropertyTaggingSelection/PropertyTaggingSelection'
import Tag from 'atoms/common/Tag'
//Types
import { ChipType } from 'types/propertyTagging'
import { PropertyTaggingType } from './PropertyTagging.d'
//Helpers
import { tagColors } from 'helpers/tag/tag'
//Styles
import * as Styled from './PropertyTagging.styled'

const PropertyTagging: FC<PropertyTaggingType> = ({ tagGroups, selectedAccountIndex, formikProps }) => {
    const { values } = formikProps
    const formTagIds = values.childRelations[selectedAccountIndex]?.tagIds
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

    const handleClickPopOver = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClosePopOver = () => {
        setAnchorEl(null)
    }

    const selectedTags: ChipType[] = useMemo(() => {
        if (formTagIds) {
            return tagGroups
                ?.map((tagGroup, tagGroupIndex) =>
                    tagGroup.tags.map((tag) => ({
                        ...tag,
                        color: tagColors[tagGroupIndex],
                        tagGroupId: tagColors[tagGroupIndex],
                    })),
                )
                ?.flat()
                ?.filter((tag) => formTagIds.map((item: string | number) => +item).includes(tag.id))
                ?.map((tag) => ({ id: tag.id!, label: tag.name, color: tag.color, tagGroupId: tag.tagGroupId }))
        } else {
            return []
        }
    }, [formTagIds, tagGroups])

    return (
        <div>
            <Styled.AddIconButton
                onClick={handleClickPopOver}
                disabled={!tagGroups?.length}
                title="Add a tag for the child account"
            >
                <Styled.AddSVGIcon disabled={!tagGroups?.length} />
            </Styled.AddIconButton>

            {selectedTags?.map((tag, id) => {
                return <Tag name={tag.label} color={tag.color} key={tag.id} />
            })}
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClosePopOver}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <PropertyTaggingSelection
                    formikProps={formikProps}
                    selectedAccountIndex={selectedAccountIndex}
                    tagGroups={tagGroups}
                />
            </Popover>
        </div>
    )
}

export default PropertyTagging
