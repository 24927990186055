import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { matchPath } from 'react-router'
import { Breadcrumbs as MuiBreadCrumbs, Typography } from '@mui/material'
// Types
import { BreadcrumbType } from './Breadcrumb.d'
// Styles
import * as Styled from './Breadcrumb.styled'

const Breadcrumb: FC<BreadcrumbType> = ({ data }) => {
    //breadcrumbNameMap object map manually defined routes to a string
    const breadcrumbNameMap: { [key: string]: string } = {
        '/account/:accountId': data || '',
    }

    let crumbs: string[] = []
    const location = useLocation()
    const pathName = location.pathname
    //create an array of path, for testing nested router const pathArray='/detail/me/123'.split('/').filter((x) => x)
    const pathArray: string[] = pathName.split('/').filter((x) => x)
    let breadcrumbNameMapMatching = Object.keys(breadcrumbNameMap).filter((item) =>
        matchPath({ path: item, end: true }, pathName),
    )

    if (breadcrumbNameMapMatching.length > 0) {
        let breadCrumbMapKey: string = Object.keys(breadcrumbNameMap).find((item) =>
            matchPath({ path: item, end: true }, pathName),
        )!
        crumbs = [breadcrumbNameMap[breadCrumbMapKey]]
    } else {
        crumbs = pathArray
    }

    if (!pathArray.length) {
        return null
    }

    return (
        <Styled.BreadcrumbWrapper>
            <MuiBreadCrumbs aria-label="breadcrumb">
                <Styled.Link to="/">Account Overview</Styled.Link>

                {crumbs.map((item: string, index: number) => {
                    const last = index === crumbs.length - 1
                    return last ? (
                        <Typography key={index} variant="subtitle2">
                            {data || item}
                        </Typography>
                    ) : (
                        <Styled.Link key={index} to={item}>
                            {item}
                        </Styled.Link>
                    )
                })}
            </MuiBreadCrumbs>
        </Styled.BreadcrumbWrapper>
    )
}

export default Breadcrumb
