import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'
import axios, { AxiosError, AxiosResponse } from 'axios'
// Context & Configs
import { AuthContext } from 'context/AuthContext'
import envConfig from 'config/env.json'
// Types
import { RCTError, RCTResponse } from 'types/api.d'
import { UseAxiosProps } from './useAxios.d'

const isProdEnv = process.env.NODE_ENV === 'production'
axios.defaults.baseURL = isProdEnv
    ? `${window.location.protocol}//api.${window.location.host}`
    : envConfig.staging.apiUrl

const useAxios = ({ defaultLoadingState = true, ...requestParams }: UseAxiosProps) => {
    const [data, setData] = useState<AxiosResponse['data']>()
    const [error, setError] = useState<RCTError | AxiosError>()
    const [loading, setLoading] = useState(defaultLoadingState)
    const { sessionToken } = useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(() => {
        fetchRequest()
    }, [])

    axios.interceptors.request.use((req) => {
        req.method ||= 'GET'

        // The entry point to get the `session_token`
        if (req.url === '/connect') {
            const token = isProdEnv
                ? new URLSearchParams(window.location.search).get('token')
                : process.env.REACT_APP_AUTH_TOKEN
            if (!token) {
                navigate('/error')
            }
            req.headers.Accept = 'application/json'
            req.headers.Authorization = `Bearer ${token}`
            // Any other API request
        } else if (req.url?.indexOf('session_token') === -1) {
            req.url += (req.url.indexOf('?') === -1 ? '?' : '&') + `session_token=${sessionToken}`
        }
        return req
    })

    const fetchRequest = () => {
        setLoading(true)
        axios
            .request(requestParams)
            .then((res) => setData(res.data))
            .catch((err) => {
                if (err.response.data?.error?.code?.toString() === '20102') {
                    navigate('/error')
                }
                setError(((err as AxiosError).response?.data as RCTResponse)?.error! || err)
            })
            .finally(() => setLoading(false))
    }

    return { data, error, loading }
}

export default useAxios
