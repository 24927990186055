import React, { createContext, useContext, useState } from 'react'
import { TableContextProps } from './TableContext.d'

const TableContext = createContext<TableContextProps>({ page: 0, rowsPerPage: 10, updatePagination: undefined })

const TableProvider = ({ children }) => {
    const [pagination, setPagination] = useState<Pick<TableContextProps, 'page' | 'rowsPerPage'>>({
        page: 0,
        rowsPerPage: 10,
    })

    const updatePagination = ({ page, rowsPerPage }) => {
        setPagination({ page, rowsPerPage })
    }

    return <TableContext.Provider value={{ ...pagination, updatePagination }}>{children}</TableContext.Provider>
}

const useTableContext = () => useContext(TableContext)

export { TableProvider, TableContext, useTableContext }
