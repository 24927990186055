import { useContext } from 'react'
import { useNavigate } from 'react-router'
import axios, { AxiosRequestConfig } from 'axios'
// Context & Configs
import { AuthContext } from 'context/AuthContext'
import envConfig from 'config/env.json'

const isProdEnv = process.env.NODE_ENV === 'production'
axios.defaults.baseURL = isProdEnv
    ? `${window.location.protocol}//api.${window.location.host}`
    : envConfig.staging.apiUrl

const useFetch = () => {
    const { sessionToken } = useContext(AuthContext)
    const navigate = useNavigate()

    axios.interceptors.request.use((req) => {
        req.method ||= 'GET'

        // The entry point to get the `session_token`
        if (req.url === '/connect') {
            const token = isProdEnv
                ? new URLSearchParams(window.location.search).get('token')
                : process.env.REACT_APP_AUTH_TOKEN
            if (!token) {
                navigate('/error')
            }
            req.headers.Accept = 'application/json'
            req.headers.Authorization = `Bearer ${token}`
            // Any other API request
        } else if (req.url?.indexOf('session_token') === -1) {
            req.url += (req.url.indexOf('?') === -1 ? '?' : '&') + `session_token=${sessionToken}`
        }
        return req
    })

    return async (params: AxiosRequestConfig) => await axios.request(params)
}

export default useFetch
