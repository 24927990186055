import React from 'react'
// Hooks
import useAxios from 'hooks/useAxios'
// Components
import LoadingSpinner from 'atoms/common/LoadingSpinner'
// Types
import { RCTConnectResponse } from 'types/api.d'
import { AuthContextData, AuthProviderProps } from './AuthContext.d'

const AuthContext = React.createContext<AuthContextData>({})

const AuthProvider = ({ children }: AuthProviderProps) => {
    const { data, loading, error } = useAxios({ url: '/connect' })

    const sessionToken = (data as RCTConnectResponse)?.sessionToken || ''

    return (
        <AuthContext.Provider value={{ sessionToken, error }}>
            {loading ? <LoadingSpinner /> : children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider }
