import React, { useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { Formik, Form } from 'formik'
import { Button, Box } from '@mui/material'
import * as Yup from 'yup'
// Contexts
import { TagProvider } from 'context/TagContext'
// Hooks
import { useNavigate, useParams } from 'react-router'
import useFetch from 'hooks/useFetch'
// Components
import LoadingSpinner from 'atoms/common/LoadingSpinner'
import Breadcrumb from 'atoms/Breadcrumb'
import TagSettings from 'organisms/TagSettings'
import BulkEdit from 'molecules/BulkEdit'
import GeneralSettings from 'molecules/AccountGeneralSettings'
import AccountChildrenTable from 'molecules/AccountChildrenTable'
import PropertiesIncluded from 'atoms/PropertiesIncluded'
import FilterChildRelationsTable from 'molecules/FilterChildRelationsTable'
// Helpers
import { displayNotification } from 'helpers/common/notifications'
import { initialAccountValue } from './AccountDetails.helper'
// Types
import { RCTError, RCTResponse } from 'types/api'
import { AccountData, AccountType, ChildRelation, TagGroupData } from 'types/account.d'
// Styles
import * as Styled from './AccountDetails.styled'

const AccountDetails = () => {
    const [loading, setLoading] = useState(false)
    const [accountData, setAccountData] = useState<AccountData>(initialAccountValue)
    const [selectedProperties, setSelectedProperties] = useState<number[]>([])
    const [childRelationsData, setChildRelationsData] = useState<ChildRelation[]>([])
    const [bulkEdited, setBulkEdited] = useState<boolean>(false)
    const { accountId } = useParams()
    const navigate = useNavigate()
    const fetchData = useFetch()

    useEffect(() => {
        fetchAccountDetails()
    }, [])

    const fetchAccountDetails = async () => {
        try {
            setLoading(true)
            const res = await fetchData({ url: `/account/${accountId}` })

            const updatedData = getUpdateAccountData(res.data.account)
            setAccountData({ ...res.data.account, childRelations: updatedData })

            if (updatedData?.length) {
                setChildRelationsData(updatedData)
            }

            setBulkEdited(false)
            setSelectedProperties([])
        } catch (error) {
            displayNotification((error as RCTError).message, { variant: 'error' })
        } finally {
            setLoading(false)
        }
    }

    const getUpdateAccountData = (accountData: AccountData) => {
        return accountData.childRelations?.map((child: ChildRelation) => {
            return {
                ...child,
                tagIds: accountData.tagGroups?.map((tagGroup: TagGroupData) => {
                    return (
                        child.tagIds
                            ?.map((tagId) => tagId.toString())
                            .find((tagId: string) =>
                                tagGroup.tags.map((tag) => tag.id?.toString()).includes(tagId.toString()),
                            ) ?? ''
                    )
                }),
            }
        })
    }

    const onPropertiesSelectionChange = (selectedIds: number[]) => {
        setSelectedProperties(selectedIds)
    }

    const handleSubmit = async (data: AccountData) => {
        try {
            const updatedData = {
                ...data,
                childRelations: data?.childRelations?.map((child) => {
                    return {
                        ...child,
                        tagIds: child?.tagIds?.filter(Boolean).map((id) => +id),
                    }
                }),
            }
            await fetchData({ method: 'post', url: `/account/${accountId}`, data: { account: updatedData } })
            displayNotification(`Change to ${accountData.name} has been saved successfully.`, { variant: 'success' })
            await fetchAccountDetails()
        } catch (error) {
            const rctErrorMsg = ((error as AxiosError).response?.data as RCTResponse)?.error?.message
            const axiosErrorMsg = (error as AxiosError).message
            displayNotification(rctErrorMsg || axiosErrorMsg, { variant: 'error' })
        }
    }

    const handleCancel = () => {
        navigate('/')
    }

    const updateChildRelationsData = (values: ChildRelation[]) => {
        setChildRelationsData(values)
    }

    const updateBulkEditData = (values: ChildRelation[]) => {
        setChildRelationsData(values)
        setBulkEdited(true)
    }

    const accountDetailsValidationSchema = Yup.object().shape({
        reportingStatus: Yup.string().required('Reporting Status is Required'),
        reportingCurrency: Yup.string().required('Reporting Currency is Required'),
        reportType: Yup.string().required('Report Type is Required'),
        exportFrequency: Yup.string().required('Export Frequency is Required'),
    })

    if (loading) {
        return <LoadingSpinner />
    }

    return (
        <TagProvider tagGroupsData={accountData.tagGroups}>
            <Breadcrumb data={accountData?.name} />
            <h2>{`${accountData.bid} - ${accountData.name}`}</h2>
            <Formik<AccountData>
                enableReinitialize
                onSubmit={handleSubmit}
                initialValues={accountData}
                validationSchema={accountDetailsValidationSchema}
            >
                {(formikProps) => {
                    //formikProps returns methods as below
                    // const { values, errors, setFieldValue, dirty, handleChange, touched, setValues } = formikProps

                    return (
                        <Form>
                            <GeneralSettings formikProps={formikProps} />

                            {accountData.type === AccountType.GROUP_ACCOUNT && (
                                <>
                                    <h2>Property Tagging</h2>
                                    <Styled.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
                                        <Box gridColumn="span 4">
                                            <PropertiesIncluded
                                                showNewPropertyLabel={false}
                                                showInTable={false}
                                                childrenCount={accountData.childRelations?.length!}
                                                includedChildrenCount={
                                                    accountData.childRelations?.filter((child) => child.included)
                                                        .length!
                                                }
                                            />
                                        </Box>
                                    </Styled.Box>

                                    <FilterChildRelationsTable
                                        tagGroups={accountData.tagGroups!}
                                        formikProps={formikProps}
                                        onUpdateFilters={updateChildRelationsData}
                                    />

                                    <Styled.DialogButtons>
                                        <BulkEdit
                                            formikProps={formikProps}
                                            tagGroups={accountData.tagGroups!}
                                            selectedIds={selectedProperties}
                                            onUpdateBulkEdit={updateBulkEditData}
                                        />
                                        <TagSettings
                                            tagGroups={accountData.tagGroups}
                                            onFinishTagSettings={fetchAccountDetails}
                                            hasUnsavedChanges={formikProps.dirty}
                                        />
                                    </Styled.DialogButtons>

                                    <AccountChildrenTable
                                        formikProps={formikProps}
                                        childRelations={childRelationsData}
                                        onSelectionChange={onPropertiesSelectionChange}
                                    />
                                </>
                            )}

                            <Styled.StickyActionsFooter>
                                <Button
                                    variant="contained"
                                    title="Save"
                                    type="submit"
                                    disabled={!formikProps.dirty && !bulkEdited}
                                >
                                    Save
                                </Button>
                                <Button onClick={handleCancel} variant="outlined" title="cancel">
                                    Cancel
                                </Button>
                            </Styled.StickyActionsFooter>
                        </Form>
                    )
                }}
            </Formik>
        </TagProvider>
    )
}

export default AccountDetails
