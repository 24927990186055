import styled from '@emotion/styled'
import MuiButton from '@mui/material/Button'
import MuiDialog from '@mui/material/Dialog'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'

export const Button = styled(MuiButton)`
    text-transform: none;
`

export const Dialog = styled(MuiDialog)`
    & .MuiDialog-paperWidthSm {
        max-width: 1000px;
        width: 80%;
    }
`

export const DialogContent = styled(MuiDialogContent)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 30px !important;
    border-top: 1px solid #bbb;
    border-bottom: 1px solid #bbb;
`

export const DialogActions = styled(MuiDialogActions)`
    justify-content: flex-start;
    padding: 20px;
`
