import styled from '@emotion/styled'
import { Icon, Typography } from '@mui/material'

export const ErrorWrapper = styled.div`
    padding: 5% 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

export const SVGIcon = styled(Icon)`
    transform: scale(2.2);
    margin: 20px 30px;
    svg {
        font-size: 1.5rem;
    }
`

export const ErrorMessage = styled(Typography)`
    text-transform: capitalize;
`
