import React from 'react'
import ReactDOM from 'react-dom/client'
// Test Helpers
import reportWebVitals from './reportWebVitals'
// Context Providers
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { AuthProvider } from 'context/AuthContext'
import { TableProvider } from 'context/TableContext'
import NotificationsProvider from 'atoms/common/NotificationsProvider'
// Routes
import AppRoutes from 'router/AppRoutes'
// Configs
import appConfig from '../package.json'
// Styles
import amadeusBaseTheme from 'themes/amadeusBaseTheme'
import './index.css'

// Logging the app version number on the first load of the app.
console.log(`RCT v${appConfig.version} has been loaded.`)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AuthProvider>
                <ThemeProvider theme={amadeusBaseTheme}>
                    <NotificationsProvider>
                        <TableProvider>
                            <AppRoutes />
                        </TableProvider>
                    </NotificationsProvider>
                </ThemeProvider>
            </AuthProvider>
        </BrowserRouter>
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
