import styled from '@emotion/styled'
//Components
import { IconButton } from '@mui/material'
import { AddCircle as MuiAddCircle } from '@mui/icons-material'
//Types
import { AddIconProps } from 'types/propertyTagging'

export const AddIconButton = styled(IconButton)`
    padding: 0;
    align-self: center;
    margin-right: 5px;
`

export const AddSVGIcon = styled(MuiAddCircle)<AddIconProps>`
    width: 22px;
    height: 22px;
    ${({ disabled }) => !disabled && 'color: #005eb8'};
`
