import React, { FC, useEffect, useState } from 'react'
import { InputAdornment } from '@mui/material'
import { Search } from '@mui/icons-material'
//hooks
import useDebounce from 'hooks/useDebounce'
//styles
import * as Styled from './SearchInput.styled'

interface SearchInputProps {
    label: string
    onSearchInputChanged: (text: string) => void
}

const SearchInput: FC<SearchInputProps> = ({ label, onSearchInputChanged }) => {
    const [text, setText] = useState<string>()
    const debouncedText = useDebounce(text, 500)

    useEffect(() => {
        if (typeof debouncedText === 'string') {
            onSearchInputChanged(debouncedText.trim())
        }
    }, [debouncedText])

    const handleTextChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setText(evt.target.value)
    }

    return (
        <Styled.TextField
            label={label}
            onChange={handleTextChange}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Search />
                    </InputAdornment>
                ),
            }}
        />
    )
}

export default SearchInput
