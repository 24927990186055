import styled from '@emotion/styled'

export const ActivationStatusWrapper = styled.div`
    display: flex;
    align-items: center;
    & svg {
        margin-right: 5px;
    }
    & span {
        text-transform: capitalize;
    }
`
