import React, { FC } from 'react'
//Components
import { FormControlLabel, FormLabel, RadioGroup } from '@mui/material'
//Types
import { TagData, TagGroupData } from 'types/account'
import { PropertyTaggingSelectionType } from './PropertyTaggingSelection.d'
//Helpers
import { tagColors } from 'helpers/tag/tag'
//Styles
import * as Styled from './PropertySelection.styled'

const PropertyTaggingSelection: FC<PropertyTaggingSelectionType> = ({
    formikProps,
    selectedAccountIndex,
    tagGroups,
}) => {
    const { values, handleChange } = formikProps
    const formikTagIdsData = values?.childRelations[selectedAccountIndex].tagIds

    const ClearAllTags = () => {
        formikProps.setFieldValue(`childRelations[${selectedAccountIndex}]['tagIds']`, [])
    }

    return (
        <>
            <Styled.Paper>
                {tagGroups?.map((tagGroup: TagGroupData, tagGroupIndex: number) => {
                    return (
                        <div key={tagGroup.id}>
                            <Styled.Section key={tagGroup.id}>
                                <FormLabel id="tag-group">{tagGroup.name}</FormLabel>
                                <RadioGroup
                                    name={`childRelations[${selectedAccountIndex}]tagIds[${tagGroupIndex}]`}
                                    aria-labelledby="tag-group"
                                    onChange={handleChange}
                                    value={formikTagIdsData?.[`${tagGroupIndex}`] ?? ''}
                                >
                                    {tagGroup.tags.map((tag: TagData) => {
                                        return (
                                            <FormControlLabel
                                                key={tag.id}
                                                value={tag.id}
                                                label={tag.name}
                                                control={<Styled.Radio customColor={tagColors[tagGroupIndex]} />}
                                            />
                                        )
                                    })}
                                </RadioGroup>

                                {tagGroupIndex !== tagGroups.length - 1 && <Styled.divider />}
                            </Styled.Section>
                        </div>
                    )
                })}
            </Styled.Paper>
            <Styled.Button variant="text" onClick={ClearAllTags}>
                Clear All
            </Styled.Button>
        </>
    )
}

export default PropertyTaggingSelection
