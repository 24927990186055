import React, { useEffect, useState } from 'react'
// Hooks
import useFetch from 'hooks/useFetch'
// Components
import Table from 'molecules/common/Table'
import SelectFilter from 'atoms/common/SelectWithClear'
import SearchInput from 'atoms/common/SearchInput'
// Helpers
import { displayNotification } from 'helpers/common/notifications'
import { accountTypeOptions, reportingStatusOptions } from 'helpers/account/selectOptions'
import { accountColumns } from './AccountList.helper'
//hooks
import { useTableContext } from 'context/TableContext'
// Types
import { PaginationType } from 'molecules/common/Table/Table.d'
import { AccountListData, AccountsFilters } from './AccountList.d'
// Styles
import * as Styled from './AccountList.styled'

export default function AccountList() {
    const [filters, setFilters] = useState<AccountsFilters>({
        searchText: '',
        accountType: '',
        reportStatus: '',
    })
    const tableContext = useTableContext()
    const initialPagination = {
        rowsPerPage: tableContext.rowsPerPage,
        rowCount: 0,
        pageCount: 0,
        pageNumber: tableContext.page,
    }

    const [accountsData, setAccountsData] = useState<AccountListData>({
        accounts: [],
        pagination: initialPagination,
    })

    const [loading, setLoading] = useState(true)
    const fetchData = useFetch()

    useEffect(() => {
        setLoading(true)
        const url = getUrlParams()
        fetchData({ url })
            .then((res) =>
                setAccountsData({
                    accounts: res.data.accounts,
                    pagination: res.data.pagination,
                }),
            )
            .catch((error) => displayNotification(error.message, { variant: 'error' }))
            .finally(() => setLoading(false))
    }, [filters, accountsData.pagination.rowsPerPage, accountsData.pagination.pageNumber])

    const getUrlParams = (ignoreSelectedPage?: boolean) => {
        let url = `/account?rows_per_page=${accountsData.pagination.rowsPerPage}&page_number=${
            ignoreSelectedPage ? 1 : accountsData.pagination.pageNumber
        }`
        url += filters.searchText ? `&search=${filters.searchText}` : ''
        url += filters.reportStatus ? `&reporting_status=${filters.reportStatus}` : ''
        url += filters.accountType ? `&account_type=${filters.accountType}` : ''
        return url
    }

    const updateSearchFilter = (searchText: string) => {
        setFilters((prevFilters) => ({ ...prevFilters, searchText }))
    }

    const updateReportStatusFilter = (reportStatus: string) => {
        setFilters((prevFilters) => ({ ...prevFilters, reportStatus }))
    }

    const updateAccountTypeFilter = (accountType: string) => {
        setFilters((prevFilters) => ({ ...prevFilters, accountType }))
    }

    const onPageChange = (pageNumber: number) => {
        setAccountsData({
            ...accountsData,
            pagination: {
                ...accountsData.pagination,
                pageNumber: pageNumber + 1,
            },
        })

        tableContext.updatePagination({ page: pageNumber + 1, rowsPerPage: accountsData.pagination.rowsPerPage })
    }

    const onPageSizeChange = (pageSize: number) => {
        setAccountsData({
            ...accountsData,
            pagination: { ...accountsData.pagination, pageNumber: 1, rowsPerPage: pageSize },
        })

        tableContext.updatePagination({ page: accountsData.pagination.pageNumber, rowsPerPage: pageSize })
    }

    return (
        <>
            <h2>Accounts Overview</h2>
            <Styled.FiltersWrapper>
                <SearchInput label="Search for BID or Account" onSearchInputChanged={updateSearchFilter} />
                <SelectFilter
                    label="Reporting Status"
                    options={reportingStatusOptions}
                    onSelectChanged={updateReportStatusFilter}
                />
                <SelectFilter
                    label="Account Type"
                    options={accountTypeOptions}
                    onSelectChanged={updateAccountTypeFilter}
                />
            </Styled.FiltersWrapper>

            <Styled.AccountsDataGridWrapper>
                <Table
                    rowSelectable={false}
                    loading={loading}
                    columns={accountColumns}
                    pageSize={accountsData.pagination.rowsPerPage}
                    pageNumber={accountsData.pagination.pageNumber}
                    rows={accountsData.accounts}
                    rowCount={accountsData.pagination.rowCount}
                    onPageChange={onPageChange}
                    onPageSizePage={onPageSizeChange}
                    getRowId={(row) => row['bid']}
                    paginationType={PaginationType.SERVER}
                />
            </Styled.AccountsDataGridWrapper>
        </>
    )
}
