import React from 'react'
import { NativeSelect } from '@mui/material'
import {
    gridPageSelector,
    gridPageCountSelector,
    gridPageSizeSelector,
    useGridSelector,
    useGridApiContext,
} from '@mui/x-data-grid'
import * as Styled from './Pagination.styled'

export default function CustomPagination() {
    const apiRef = useGridApiContext()
    const page = useGridSelector(apiRef, gridPageSelector)
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)
    const pageSize = gridPageSizeSelector(apiRef)
    const rowsPerPageOptions = [10, 20, 50, 100]

    const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        apiRef.current.setPageSize(parseInt(event.target.value))
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        apiRef.current.setPage(value - 1)
    }

    return (
        <Styled.PaginationWrapper>
            <Styled.PaginationSection
                color={'primary'}
                page={page + 1}
                count={pageCount}
                showFirstButton
                showLastButton
                onChange={handlePageChange}
            />
            <Styled.RowSelectionSection>
                <span>Rows Per Page :</span>
                <NativeSelect onChange={handlePageSizeChange} value={pageSize}>
                    {rowsPerPageOptions.map((size) => (
                        <option value={size} key={size}>
                            {size}
                        </option>
                    ))}
                </NativeSelect>
            </Styled.RowSelectionSection>
        </Styled.PaginationWrapper>
    )
}
