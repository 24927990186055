import styled from '@emotion/styled'
import { DataGrid } from '@mui/x-data-grid'

export const Table = styled(DataGrid)`
    border: none;

    .MuiDataGrid-virtualScrollerContent {
        /* default MUI row height is 52px + 3px (x2) for margins + 1px (x2) for borders [.MuiDataGrid-row] = (52+6+2) = 60px */
        height: ${({ rows, pageSize }) => ((rows.length > pageSize! ? pageSize : rows.length) || 1) * 60}px !important;
    }

    .MuiDataGrid-row {
        border: 1px solid #e0e0e0;
        margin: 3px 0;
        width: calc(100% - 4px);
        border-radius: 2px;
    }

    .MuiDataGrid-cell {
        border-bottom: none;
    }

    .MuiDataGrid-columnSeparator {
        display: none;
    }

    .MuiDataGrid-columnHeaders {
        border-bottom: none;
    }

    .MuiDataGrid-columnHeaderTitle {
        font-weight: 800;
    }

    .MuiDataGrid-footerContainer {
        border-top: none;
    }
`
