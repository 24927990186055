import styled from '@emotion/styled'

import { Box as MuiBox } from '@mui/material'

export const DialogButtons = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
`
export const Box = styled(MuiBox)`
    margin-top: 16px;
    margin-bottom: 16px;
`

export const StickyActionsFooter = styled.div`
    height: 50px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 30px;
    width: calc(100% - 60px);
    background: white;
    z-index: 1;
    padding: 15px 30px 10px;
    border-top: 1px solid rgba(130, 130, 130, 0.1);

    & > button {
        margin-right: 10px;
    }
`
