import React from 'react'
import { Typography, Box } from '@mui/material'

import Select from 'atoms/common/Select'
import {
    currencyOptions,
    reportingStatusOptions,
    reportTypeOptions,
    exportFrequencyOptions,
} from 'helpers/account/selectOptions'
import { AccountGeneralSettingsProps } from './AccountGeneralSettings.d'

const GeneralSettings = ({ formikProps }: AccountGeneralSettingsProps) => {
    const { values, errors, touched, handleChange } = formikProps

    return (
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={4}>
            <Box gridColumn="span 12">
                <Typography variant="h6">General Setting</Typography>
            </Box>
            <Box gridColumn="span 3">
                <Select
                    label="Reporting Status"
                    options={reportingStatusOptions}
                    defaultValue={values.reportingStatus}
                    onSelectChanged={handleChange}
                    name="reportingStatus"
                    error={errors.reportingStatus && touched.reportingStatus && errors.reportingStatus}
                />
            </Box>

            <Box gridColumn="span 9"></Box>

            <Box gridColumn="span 3">
                <Select
                    label="Currency"
                    required
                    options={currencyOptions}
                    onSelectChanged={handleChange}
                    defaultValue={values.reportingCurrency}
                    name="reportingCurrency"
                    error={errors.reportingCurrency && touched.reportingCurrency && errors.reportingCurrency}
                />
            </Box>

            <Box gridColumn="span 3">
                <Select
                    label="Report Type"
                    onSelectChanged={handleChange}
                    required
                    options={reportTypeOptions}
                    defaultValue={values.reportType}
                    name="reportType"
                    error={errors.reportType && touched.reportType && errors.reportType}
                />
            </Box>

            <Box gridColumn="span 3">
                <Select
                    label="Export Frequency"
                    required
                    options={exportFrequencyOptions}
                    onSelectChanged={handleChange}
                    defaultValue={values.exportFrequency}
                    name="exportFrequency"
                    error={errors.exportFrequency && touched.exportFrequency && errors.exportFrequency}
                />
            </Box>

            <Box gridColumn="span 12">
                <hr />
            </Box>
        </Box>
    )
}

export default GeneralSettings
