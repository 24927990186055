import React, { FC, useEffect, useState } from 'react'
import { Switch as MuiSwitch } from '@mui/material'

import { SwitchProps } from './Switch.d'

const Switch: FC<SwitchProps> = ({ defaultValue, name, handleChange }) => {
    const [checked, setChecked] = useState<boolean>(defaultValue)

    useEffect(() => {
        setChecked(defaultValue)
    }, [defaultValue])

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
        handleChange?.(event)
    }

    return <MuiSwitch name={name} onChange={onChange} checked={checked} color="primary" />
}

export default Switch
