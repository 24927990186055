import styled from '@emotion/styled'
import { Typography } from '@mui/material'

import amadeusBaseTheme from 'themes/amadeusBaseTheme'

import { SelectLabelProps } from './Select.d'

export const SelectWrapper = styled.div`
    display: flex;
    flex-direction: column;
    & .Mui-disabled > .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.095) !important;
    }
`

export const ErrorTypography = styled(Typography)`
    color: ${amadeusBaseTheme.palette.error.main};
    font-size: 12px;
`

export const SelectLabel = styled('label', {
    shouldForwardProp: (propName) => propName !== 'hasError',
})<SelectLabelProps>`
    color: ${({ hasError }) =>
        hasError ? amadeusBaseTheme.palette.error.main : amadeusBaseTheme.palette.common.black};
    margin: 0.5em 0;
`
