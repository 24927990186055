import React, { FC, useState } from 'react'
//Components
import { BaseTextFieldProps, Checkbox, Divider, TextField } from '@mui/material'
import Button from '../Button'
//Types
import { MultiSelectProps, OptionType } from './MultiSelect.d'
import { SelectOptionType } from '../Select/Select.d'
//Styles
import * as Styled from './MultiSelect.styled'

const MultiSelect: FC<MultiSelectProps> = ({
    options,
    onSelectChanged,
    defaultValue,
    label,
    prefixLabel,
    disabled = false,
    error,
    required,
    withSelectActions,
    withSelectedFooter,
}) => {
    const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([])

    const handleSelectionChanged = (event: React.SyntheticEvent<Element, Event>, newValues: OptionType[]) => {
        onSelectChanged(newValues.map((newVal) => newVal.value))
        setSelectedOptions(newValues)
    }

    const handleTextEntered = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            const inputText = (event.target as EventTarget & HTMLInputElement).value
            const idsList = inputText.split(',')
            const matchedOptions = options.filter((option) =>
                idsList.find((id) => option.value.toString() === id.trim()),
            )
            const updatedSelection = [...selectedOptions, ...matchedOptions]
            onSelectChanged(updatedSelection.map((option) => option.value))
            setSelectedOptions(updatedSelection)
        }
    }

    const clearAll = () => {
        onSelectChanged([])
        setSelectedOptions([])
    }

    const selectAll = () => {
        const selectedValues = options.filter((option) => option.type !== SelectOptionType.DIVIDER && option.value)

        onSelectChanged(selectedValues.map((option) => option.value))
        setSelectedOptions(selectedValues)
    }

    return (
        <Styled.Wrapper>
            <Styled.HeaderContainer>
                {prefixLabel && <Styled.Label>{`${prefixLabel}${required ? ' *' : ''}`}</Styled.Label>}
                {withSelectActions && (
                    <Styled.Title>
                        <Button variant="text" onClick={selectAll}>
                            Select All ({options.length})
                        </Button>
                        <Button variant="text" onClick={clearAll}>
                            Clear All
                        </Button>
                    </Styled.Title>
                )}
            </Styled.HeaderContainer>
            <Styled.Autocomplete
                data-testid={'autocomplete'}
                multiple
                noOptionsText={'No match found. - For comma-separated IDs, hit "Enter" to add the list.'}
                readOnly={disabled}
                options={options}
                value={selectedOptions}
                disabled={disabled}
                isOptionEqualToValue={(option: OptionType, selectedOption: OptionType) =>
                    option.value === selectedOption.value
                }
                defaultValue={defaultValue}
                getOptionLabel={(option: OptionType) =>
                    option.type! === SelectOptionType.DIVIDER ? '' : option.labelText ?? option.component.toString()
                }
                onChange={handleSelectionChanged}
                renderInput={(params) => (
                    <TextField
                        {...(params as BaseTextFieldProps)}
                        variant="outlined"
                        onKeyDown={handleTextEntered}
                        label={error?.length || required ? `${label} *` : label}
                        error={!!error}
                    />
                )}
                renderOption={(props, option: OptionType, { selected }) => {
                    if (option.type === SelectOptionType.DIVIDER) {
                        return <Divider key={option.value} />
                    } else {
                        return (
                            <li {...props} key={option.value}>
                                <Checkbox checked={selected} />
                                {option.component}
                            </li>
                        )
                    }
                }}
            />
            {withSelectedFooter && selectedOptions.length > 0 && (
                <Styled.FooterMessage>{`Selected: ${selectedOptions.length}`}</Styled.FooterMessage>
            )}
            {error && <Styled.ErrorMessage>{error}</Styled.ErrorMessage>}
        </Styled.Wrapper>
    )
}

export default MultiSelect
