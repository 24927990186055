import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'

// import Breadcrumb from '../components/Breadcrumb'

import * as Styled from './AppLayout.styled'

const AppLayout: FC<any> = ({ component: Component, ...rest }) => {
    return (
        <Styled.AppLayout>
            {/*<Breadcrumb/>*/}
            <Outlet />
        </Styled.AppLayout>
    )
}

export default AppLayout
