import React, { useContext, useMemo, useState } from 'react'
// Components
import { Checkbox, FormControlLabel, Radio } from '@mui/material'
import Button from 'atoms/common/Button'
import Dialog from 'atoms/common/Dialog'
import Select from 'atoms/common/Select'
import TagSelectList from 'atoms/TagSelectList'
// Types
import { BulkEditProps, EditState, ReportInclusion } from './BulkEdit.d'
// Styles
import * as Styled from './BulkEdit.styled'
import { TagContext } from 'context/TagContext'

const defaultEditState: EditState = {
    checked: false,
    value: '',
}

export default function BulkEdit({ formikProps, tagGroups, selectedIds, onUpdateBulkEdit }: BulkEditProps) {
    const selectedPropertiesCount = selectedIds.length
    const [dialogOpened, setDialogOpened] = useState(false)
    const [reportInclusion, setReportInclusion] = useState<EditState>(defaultEditState)
    const [tagAssign, setTagAssign] = useState<EditState>(defaultEditState)
    const [tagRemoval, setTagRemoval] = useState<EditState>(defaultEditState)

    const { tagsLookup } = useContext(TagContext)
    const { values, setFieldValue } = formikProps

    const tagsList = useMemo(() => TagSelectList(tagGroups), [tagGroups])

    const openBulkEditDialog = () => {
        setDialogOpened(true)
    }

    const closeBulkEditDialog = () => {
        setDialogOpened(false)
        resetDialogDefaults()
    }

    const applyBulkEdit = () => {
        const updatedChildRelation = [...formikProps.values.childRelations!]

        selectedIds.forEach((accountId) => {
            const accountIndex = values.childRelations?.findIndex((property) => property.account.bid === accountId)

            if (reportInclusion?.value) {
                const isIncluded = reportInclusion.value === ReportInclusion.INCLUDE
                setFieldValue(`childRelations[${accountIndex}].included`, isIncluded)
                updatedChildRelation[accountIndex!].included = isIncluded
            }

            if (tagsList.length && tagsLookup) {
                const tagIds = values.childRelations![accountIndex!].tagIds
                const updatedTagIds = [...tagIds!]

                if (tagAssign?.value) {
                    const tagGroupIndex = tagsLookup[tagAssign.value].groupIndex
                    updatedTagIds[tagGroupIndex] = tagAssign.value
                    setFieldValue(`childRelations[${accountIndex}].tagIds`, updatedTagIds)
                    updatedChildRelation[accountIndex!].tagIds = updatedTagIds
                }
                if (tagRemoval?.value && tagIds?.includes(tagRemoval.value)) {
                    const tagGroupIndex = tagsLookup[tagRemoval.value].groupIndex
                    updatedTagIds[tagGroupIndex] = ''
                    setFieldValue(`childRelations[${accountIndex}].tagIds`, updatedTagIds)
                    updatedChildRelation[accountIndex!].tagIds = updatedTagIds
                }
            }
        })

        onUpdateBulkEdit(updatedChildRelation)
        setDialogOpened(false)
        resetDialogDefaults()
    }

    const resetDialogDefaults = () => {
        setReportInclusion(defaultEditState)
        setTagAssign(defaultEditState)
        setTagRemoval(defaultEditState)
    }

    return (
        <>
            <Button variant="outlined" disabled={!selectedPropertiesCount} onClick={openBulkEditDialog}>
                {`Bulk Edit ${selectedPropertiesCount ? `(${selectedPropertiesCount})` : ''}`}
            </Button>

            <Dialog
                open={dialogOpened}
                dialogTitle={`Bulk Edit - ${selectedPropertiesCount} Selected Properties`}
                acceptButton={{
                    label: 'Apply Changes',
                    onClick: applyBulkEdit,
                    disabled: !(reportInclusion?.value || tagAssign?.value || tagRemoval?.value),
                }}
                declineButton={{ label: 'Cancel', onClick: closeBulkEditDialog }}
            >
                <Styled.BulkEditContent>
                    <Checkbox
                        name="report-inclusion"
                        value={reportInclusion?.checked}
                        onChange={(_, checked) => setReportInclusion({ checked, value: '' })}
                    />
                    <label id="report-inclusion">Reporting</label>
                    <Styled.RadioGroup
                        row
                        name="report-inclusion"
                        value={reportInclusion?.value}
                        onChange={(_, value) => setReportInclusion({ checked: true, value })}
                    >
                        <FormControlLabel
                            value={ReportInclusion.INCLUDE}
                            label="Include"
                            disabled={!reportInclusion?.checked}
                            control={<Radio />}
                        />
                        <FormControlLabel
                            value={ReportInclusion.EXCLUDE}
                            label="Exclude"
                            disabled={!reportInclusion?.checked}
                            control={<Radio />}
                        />
                    </Styled.RadioGroup>

                    <Checkbox
                        name="set-tag"
                        value={tagAssign?.checked}
                        disabled={!tagsList.length}
                        onChange={(_, checked) => setTagAssign({ checked, value: '' })}
                    />
                    <Styled.CheckboxLabel id="set-tag" disabled={!tagsList.length}>
                        Set Tag
                    </Styled.CheckboxLabel>
                    <Select
                        name="set-tag"
                        defaultValue=""
                        disabled={!tagAssign?.checked}
                        options={tagsList}
                        onSelectChanged={(event) => setTagAssign({ checked: true, value: event.target.value })}
                    />

                    <Checkbox
                        name="remove-tag"
                        value={tagRemoval?.checked}
                        disabled={!tagsList.length}
                        onChange={(_, checked) => setTagRemoval({ checked, value: '' })}
                    />
                    <Styled.CheckboxLabel id="remove-tag" disabled={!tagsList.length}>
                        Remove Tag
                    </Styled.CheckboxLabel>
                    <Select
                        name="remove-tag"
                        defaultValue=""
                        disabled={!tagRemoval?.checked}
                        options={tagsList}
                        onSelectChanged={(event) => setTagRemoval({ checked: true, value: event.target.value })}
                    />
                </Styled.BulkEditContent>
            </Dialog>
        </>
    )
}
