import { GridColDef } from '@mui/x-data-grid'
// Components
import ActivationStatus from 'atoms/common/ActivationStatus'
import PropertiesIncluded from 'atoms/PropertiesIncluded'
// Types
import { Account } from './AccountList.d'
// Styles
import * as Styled from './AccountList.styled'

export const initialPagination = {
    rowsPerPage: 10,
    rowCount: 0,
    pageCount: 0,
    pageNumber: 1,
}

export const accountColumns: GridColDef[] = [
    { field: 'name', headerName: 'Account', flex: 2, sortable: false },
    { field: 'bid', headerName: 'BID', type: 'string', flex: 0.5, sortable: false },
    {
        field: 'reportingStatus',
        headerName: 'Reporting Status',
        flex: 1,
        renderCell: (params) => <ActivationStatus status={params.value} />,
        sortable: false,
    },
    {
        field: 'childrenCount',
        headerName: 'Properties Included',
        flex: 2.5,
        renderCell: (params) => {
            if (params.row.type === 'single' || !params.row?.childrenCount) {
                return <> - </>
            } else {
                return (
                    <PropertiesIncluded
                        childrenCount={params.row?.childrenCount}
                        includedChildrenCount={params.row?.includedChildrenCount}
                        showNewPropertyLabel={!!params.row?.newChildrenCount}
                    />
                )
            }
        },
        sortable: false,
    },
    {
        field: 'type',
        headerName: 'Account Type',
        flex: 2,
        renderCell: (params) => getAccountTypeCell(params.row),
        sortable: false,
    },
    {
        field: 'details',
        headerName: '',
        flex: 1,
        align: 'center',
        renderCell: (params) => <Styled.Link to={`/account/${params.row.bid}`}>More Details</Styled.Link>,
        sortable: false,
    },
]

const getAccountTypeCell = (account: Account): string => {
    let displayText = account.type === 'single' ? 'Property' : 'Group Account'
    if (account.parentCount) {
        displayText += ' part of Group Account'
    }
    return displayText
}
