import React, { FC, useEffect, useState } from 'react'
// MUI Components
import { Divider, MenuItem, TextField } from '@mui/material'
// Types
import { SelectProps, SelectOption, SelectOptionType } from './Select.d'
// Styles
import * as Styled from './Select.styled'

const Select: FC<SelectProps> = ({
    label,
    required,
    defaultValue,
    disabled,
    options,
    onSelectChanged,
    name,
    error = '',
}) => {
    const [value, setValue] = useState<string | unknown>(defaultValue || '')

    useEffect(() => {
        setValue(defaultValue || '')
    }, [defaultValue])

    const handleSelectChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = event?.target.value as string
        setValue(value)
        onSelectChanged(event)
    }

    return (
        <Styled.SelectWrapper>
            {label && (
                <Styled.SelectLabel hasError={error.length > 0}>
                    {label}
                    {required && ' *'}
                </Styled.SelectLabel>
            )}

            <TextField
                error={error.length > 0}
                select
                label={label}
                value={value}
                onChange={handleSelectChange}
                disabled={disabled}
                margin={label ? 'normal' : 'none'}
                fullWidth
                name={name}
            >
                {options.map((option: SelectOption, index: number) => {
                    if (option.type === SelectOptionType.DIVIDER) {
                        return <Divider key={index} />
                    } else {
                        return (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                disabled={option.disabled}
                                defaultValue={defaultValue}
                            >
                                {option.component}
                            </MenuItem>
                        )
                    }
                })}
            </TextField>
            {error.length > 0 && <Styled.ErrorTypography variant="subtitle2">{error}</Styled.ErrorTypography>}
        </Styled.SelectWrapper>
    )
}

export default Select
