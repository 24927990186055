import styled from '@emotion/styled'
import { TextField as MuiTextField, Button as MuiButton, IconButton } from '@mui/material'
import { AddCircle as MuiAddCircle } from '@mui/icons-material'
import { AddIconProps } from 'types/propertyTagging'

export const TagGroupWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
`

export const ColoredIndicator = styled.div`
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background-color: ${({ color }) => color};
`

export const TextField = styled(MuiTextField)`
    margin: 10px 15px;
    width: 200px;
    & input {
        padding: 7px;
    }
`

export const AddIconButton = styled(IconButton)`
    padding: 0;
    align-self: center;
`

export const AddSVGIcon = styled(MuiAddCircle)<AddIconProps>`
    width: 22px;
    height: 22px;
    ${({ disabled }) => !disabled && 'color: #005eb8'};
`

export const PopoverContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
`

export const AddTagSection = styled.div`
    display: flex;
    flex-direction: row;
`

export const Button = styled(MuiButton)`
    text-transform: none;
    margin: auto 15px auto 0;
`

export const ErrorMessage = styled.div`
    color: #ff1816;
    margin: 0 15px;
`
