import styled from '@emotion/styled'
import { Autocomplete as MuiAutoComplete } from '@mui/material'
import amadeusBaseTheme from 'themes/amadeusBaseTheme'

export const Autocomplete = styled(MuiAutoComplete)`
    & .MuiInputBase-root {
        padding: 6px;
    }
`

export const Wrapper = styled.div`
    width: 100%;
`

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const Title = styled.div`
    margin-top: -37px !important;
`

export const Label = styled.label`
    font-size: 16px;
`

export const FooterMessage = styled.div`
    color: #bbb;
    font-size: 12px;
`

export const ErrorMessage = styled.div`
    color: ${amadeusBaseTheme.palette.error.main};
    font-size: 12px;
`
