import styled from '@emotion/styled'
import { Link as RouterLink } from 'react-router-dom'

export const FiltersWrapper = styled.div`
    & > div {
        width: 20%;
        margin: 0 25px 15px 0;
    }
`

export const AccountsDataGridWrapper = styled.div`
    width: 100%;
`

export const Link = styled(RouterLink)`
    font-weight: bold;
    text-decoration: none;
    color: #005eb8;
`
