import React from 'react'
import { SnackbarProvider } from 'notistack'
import { NotificationProviderProps } from './NotificationsProvider.d'
import * as Styled from './NotificationsProvider.styled'

export default function NotificationProvider({ children }: NotificationProviderProps) {
    return (
        <SnackbarProvider
            Components={{
                success: Styled.Notifications,
                error: Styled.Notifications,
                info: Styled.Notifications,
                warning: Styled.Notifications,
                default: Styled.Notifications,
            }}
        >
            {children}
        </SnackbarProvider>
    )
}
