import React, { useState } from 'react'

import Pagination from 'atoms/common/Pagination'
import { TableTypeProps } from './Table.d'
import * as Styled from './Table.styled'

const Table: React.FC<TableTypeProps> = ({
    loading,
    columns,
    rows,
    rowCount,
    pageSize,
    pageNumber,
    onPageChange,
    onPageSizePage,
    onSelectionChange,
    checkboxSelection = false,
    disableSelectionOnClick = true,
    rowSelectable = false,
    getRowId,
    paginationType,
}) => {
    const [page, setPage] = useState(pageNumber ? pageNumber - 1 : 0)

    const updatePage = (page: number) => {
        onPageChange(page)
        setPage(page)
    }

    const updatePageSize = (pageSize: number) => {
        onPageSizePage(pageSize)
        setPage(0)
    }

    return (
        <Styled.Table
            loading={loading}
            autoHeight
            isRowSelectable={() => rowSelectable}
            checkboxSelection={checkboxSelection}
            onSelectionModelChange={(updatedSelectedIds) => {
                onSelectionChange?.(updatedSelectedIds)
            }}
            disableSelectionOnClick={disableSelectionOnClick}
            pagination
            paginationMode={paginationType}
            columns={columns}
            rows={rows}
            getRowId={(row) => getRowId(row)}
            rowCount={rowCount}
            onPageChange={updatePage}
            onPageSizeChange={updatePageSize}
            pageSize={pageSize}
            page={page}
            components={{
                Pagination,
            }}
        />
    )
}

export default Table
