import styled from '@emotion/styled'
import { PropertiesIncludedWrapperProps } from './PropertiesIncluded.d'

export const PropertiesIncludedWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'showInTable',
})<PropertiesIncludedWrapperProps>`
    display: flex;
    & .MuiLinearProgress-root {
        background-color: #d8d8d8;
        width: ${({ showInTable }) => (showInTable ? 'auto' : '500px')};
        height: ${({ showInTable }) => (showInTable ? '4px' : '8px')};
        margin-top: ${({ showInTable }) => (showInTable ? 'auto' : '4px')};
    }
`

export const NewPropertyAddedTag = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;
    padding: 0 5px;
    border-radius: 5px;
    background-color: #398633;
    color: #fff;
    font-size: smaller;
`
