import styled from '@emotion/styled'
//Components
import { Link as RouterLink } from 'react-router-dom'
// Types
import { CustomColorType } from 'types/propertyTagging'

export const AccountPropertiesDataGridWrapper = styled.div`
    width: 100%;
    margin-bottom: 50px;
`

export const HeaderChip = styled('span', { shouldForwardProp: (prop) => prop !== 'customColor' })<CustomColorType>`
    position: relative;
    width: 70px;
    height: 20px;
    border-radius: 3px;
    background-color: #80808012;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    font-size: 10px;

    &::before {
        display: inline-block;
        width: 8px;
        height: 8px;
        content: '';
        background-color: ${(prop) => prop.customColor};
        position: absolute;
        left: 3px;
        border-radius: 50%;
    }
`
export const Link = styled(RouterLink)`
    font-weight: bold;
    text-decoration: none;
    color: #005eb8;
`
