import React from 'react'
//Helpers
import { tagColors } from 'helpers/tag/tag'
//Types
import { TagGroupData } from 'types/account'
import { SelectOptionType } from 'atoms/common/Select/Select.d'
import { OptionType } from 'atoms/common/MultiSelect/MultiSelect.d'
//Styles
import * as Styled from './TagSelectList.styled'

const TagSelectList = (tagGroups?: TagGroupData[]): OptionType[] => {
    const allTagOptions: OptionType[] = []
    tagGroups?.forEach((group, groupIndex) => {
        const tagOptions: OptionType[] = group.tags.map((tag) => {
            const labelText = `${group.name} - ${tag.name}`
            return {
                component: <Styled.TagOption tagColor={tagColors[groupIndex]}>{labelText}</Styled.TagOption>,
                value: tag.id?.toString()!,
                name: labelText,
                labelText,
            }
        })

        if (groupIndex < tagGroups.length - 1) {
            tagOptions.push({ type: SelectOptionType.DIVIDER, value: groupIndex.toString() })
        }
        allTagOptions.push(...tagOptions)
    })
    return allTagOptions
}

export default TagSelectList
