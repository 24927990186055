import React, { FC, useEffect, useMemo, useState } from 'react'
//Components
import { Box } from '@mui/material'
import SearchInput from 'atoms/common/SearchInput'
import SelectFilter from 'atoms/common/SelectWithClear'
import MultiSelect from 'atoms/common/MultiSelect'
import TagSelectList from 'atoms/TagSelectList'
//Helpers
import { reportingIncludedOptions, reportingStatusOptions } from 'helpers/account/selectOptions'
//Type
import { FilterChildRelationsTableProps } from './FilterChildRelationsTable.d'
import { ChildRelation } from 'types/account'
//Styled
import * as Styled from './FilterChildRelationsTable.styled'

const FilterChildRelationsTable: FC<FilterChildRelationsTableProps> = ({ tagGroups, formikProps, onUpdateFilters }) => {
    const formikChildRelations = formikProps.values.childRelations
    const [filteredByStatus, setFilteredByStatus] = useState(formikChildRelations)
    const [filteredByReportIncluded, setFilteredByReportIncluded] = useState(formikChildRelations)
    const [filteredByPropertyNameOrId, setFilteredByPropertyNameOrId] = useState(formikChildRelations)
    const [filteredByTag, setFilteredByTag] = useState(formikChildRelations)
    const [childRelations, setChildRelations] = useState<ChildRelation[]>(formikChildRelations)
    const tagsList = useMemo(() => TagSelectList(tagGroups), [tagGroups])

    useEffect(() => {
        setChildRelations(formikProps.values.childRelations)
    }, [formikProps.values.childRelations])

    useEffect(() => {
        const filtersGroup = [filteredByPropertyNameOrId, filteredByStatus, filteredByReportIncluded, filteredByTag]
        const filteredChildRelations: ChildRelation[] = filtersGroup.reduce((intersectedFilter, currentFilter) =>
            intersectedFilter.filter((intersectedItem: any) =>
                currentFilter.some((currFilterItem: any) => currFilterItem.account.bid === intersectedItem.account.bid),
            ),
        )

        const lastFiltered = childRelations.filter((child) =>
            filteredChildRelations.find((filteredChild) => filteredChild.account.bid === child.account.bid),
        )

        onUpdateFilters?.(lastFiltered)
    }, [filteredByStatus, filteredByReportIncluded, filteredByPropertyNameOrId, filteredByTag, childRelations])

    const searchProperty = (searchString: string | number) => {
        let filteredArray: ChildRelation[] | []
        if (searchString) {
            const trimmedString = searchString.toString().toLowerCase().trim()
            filteredArray = childRelations.filter(
                (child) =>
                    child.account.name.toLowerCase().includes(trimmedString) ||
                    child.account.bid.toString().includes(trimmedString),
            )
        } else {
            filteredArray = childRelations
        }

        setFilteredByPropertyNameOrId(filteredArray)
    }

    const reportingStatusFilter = (status: string) => {
        const filteredChildRelations = status
            ? childRelations.filter((child) => child.account.reportingStatus === status)
            : childRelations
        setFilteredByStatus(filteredChildRelations)
    }

    const reportingIncludedFilter = (included: string) => {
        const filteredChildRelations = included
            ? childRelations.filter((child) => child.included === (included === 'true'))
            : childRelations
        setFilteredByReportIncluded(filteredChildRelations)
    }

    const filterByTag = (selectedTags: string[]) => {
        const filteredChildRelations = selectedTags.length
            ? childRelations.filter(
                  (child) => child.tagIds?.filter((id) => selectedTags.includes(id.toString()))?.length,
              )
            : childRelations

        setFilteredByTag(filteredChildRelations)
    }

    return (
        <Styled.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
            <Box gridColumn="span 2">
                <SearchInput label="Search for Property" onSearchInputChanged={searchProperty} />
            </Box>
            <Box gridColumn="span 2">
                <SelectFilter
                    label="Individual Reporting Status"
                    options={reportingStatusOptions}
                    onSelectChanged={reportingStatusFilter}
                />
            </Box>
            <Box gridColumn="span 2">
                <SelectFilter
                    label="Include for Reporting"
                    options={reportingIncludedOptions}
                    onSelectChanged={reportingIncludedFilter}
                />
            </Box>
            <Box gridColumn="span 2">
                <MultiSelect
                    options={tagsList}
                    label="Search By Tag"
                    disabled={!tagsList.length}
                    onSelectChanged={filterByTag}
                />
            </Box>
        </Styled.Box>
    )
}

export default FilterChildRelationsTable
