import React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import { DialogProps } from './Dialog.d'
import * as Styled from './Dialog.styles'

const Dialog = ({ open, children, onClose, dialogTitle, acceptButton, declineButton }: DialogProps) => {
    return (
        <Styled.Dialog open={open} onClose={onClose}>
            {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}

            <Styled.DialogContent>{children}</Styled.DialogContent>

            {(acceptButton || declineButton) && (
                <Styled.DialogActions>
                    {acceptButton && (
                        <Styled.Button
                            variant="contained"
                            onClick={acceptButton.onClick}
                            disabled={acceptButton.disabled}
                        >
                            {acceptButton.label}
                        </Styled.Button>
                    )}
                    {declineButton && (
                        <Styled.Button
                            variant="outlined"
                            onClick={declineButton.onClick}
                            disabled={declineButton.disabled}
                        >
                            {declineButton.label}
                        </Styled.Button>
                    )}
                </Styled.DialogActions>
            )}
        </Styled.Dialog>
    )
}

export default Dialog
