import React, { FC } from 'react'
//Components
import { LinearProgress, Box } from '@mui/material'
//Types
import { PropertiesIncludedProps } from './PropertiesIncluded.d'
//Styles
import * as Styled from './PropertiesIncluded.styled'

const PropertiesIncluded: FC<PropertiesIncludedProps> = ({
    includedChildrenCount,
    childrenCount,
    showNewPropertyLabel = false,
    showInTable = true,
}) => {
    const numberOfIncludedChildrenCount = Number(includedChildrenCount) || 0
    const includedChildrenPercentage = (includedChildrenCount / Number(childrenCount)) * 100
    return (
        <Styled.PropertiesIncludedWrapper showInTable={showInTable}>
            <Box gridColumn="span 8">
                {numberOfIncludedChildrenCount} out of {childrenCount} properties{' '}
                {numberOfIncludedChildrenCount < 2 ? 'has' : 'have'} been included.
                <LinearProgress variant="determinate" value={includedChildrenPercentage} />
            </Box>
            {showNewPropertyLabel && <Styled.NewPropertyAddedTag>New Property Added</Styled.NewPropertyAddedTag>}
        </Styled.PropertiesIncludedWrapper>
    )
}

export default PropertiesIncluded
