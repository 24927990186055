import ActivationStatus from 'atoms/common/ActivationStatus'
import { SelectOption } from 'atoms/common/Select/Select.d'
import { AccountType, Currency, FREQUENCY, ReportStatus } from 'types/account.d'

export const reportingStatusOptions: SelectOption[] = [
    { value: ReportStatus.ACTIVE, component: <ActivationStatus status="active" /> },
    { value: ReportStatus.INACTIVE, component: <ActivationStatus status="inactive" /> },
]

export const reportingIncludedOptions: SelectOption[] = [
    { value: 'true', component: 'Reporting On' },
    { value: 'false', component: 'Reporting Off' },
]

export const accountTypeOptions: SelectOption[] = [
    { value: AccountType.PROPERTY, component: 'Property' },
    { value: AccountType.GROUP_ACCOUNT, component: 'Group Account' },
]

export const currencyOptions: SelectOption[] = [
    { value: Currency.USD, component: Currency.USD },
    { value: Currency.EUR, component: Currency.EUR },
    { value: Currency.GBP, component: Currency.GBP },
    { value: Currency.AUD, component: Currency.AUD },
]

export const exportFrequencyOptions: SelectOption[] = [
    { value: FREQUENCY.MONTHLY, component: 'Monthly', disabled: false },
    { value: FREQUENCY.WEEKLY, component: 'Weekly', disabled: true },
]

enum REPORT_TYPE {
    SMA = 'SMA',
    SME = 'SME',
    SMS = 'SMS',
}

export const reportTypeOptions: SelectOption[] = [
    { value: REPORT_TYPE.SMA, component: REPORT_TYPE.SMA, disabled: false },
    { value: REPORT_TYPE.SMS, component: REPORT_TYPE.SMS, disabled: true },
    { value: REPORT_TYPE.SME, component: REPORT_TYPE.SME, disabled: true },
]
