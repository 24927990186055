import { AccountData } from 'types/account.d'

export const initialAccountValue: AccountData = {
    bid: 0,
    reportingCurrency: '',
    exportFrequency: '',
    reportType: '',
    reportingStatus: '',
    type: '',
    name: '',
    updatedAt: '',
    version: 0,
    childRelations: [],
}
