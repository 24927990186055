import Styled from '@emotion/styled'
//Components
import { Button as MuiButton, Radio as MuiRadio } from '@mui/material'
//Types
import { CustomColorType } from 'types/propertyTagging.d'

export const Paper = Styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px 15px 30px 15px;
    min-width: 150px !important;
    min-height: 120px;
`

export const divider = Styled.div`
    height: 100px;
    width: 1px;
    background: rgb(0 0 0 / 13%);
    position: absolute;
    right: 8px;
    top: 10px;
`

export const Section = Styled.div`
   padding: 0 10px;
    position: relative
`

export const Radio = Styled(MuiRadio, { shouldForwardProp: (prop) => prop !== 'customColor' })<CustomColorType>`
  color: ${({ customColor }) => customColor};
    '&.Mui-checked': {
     color: ${({ customColor }) => customColor};
   }
`

export const Button = Styled(MuiButton)`
    font-size: 12px;
    text-transform: none;
    align-content: flex-start;
    position: absolute;
    right: 5px;
    bottom: 0px;

`
