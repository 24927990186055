import { FormikProps } from 'formik'
import { AccountData, ChildRelation, TagGroupData } from 'types/account.d'

export interface BulkEditProps {
    formikProps: FormikProps<AccountData>
    selectedIds: number[]
    tagGroups: TagGroupData[]
    onUpdateBulkEdit: (value: ChildRelation[]) => void
}

export interface EditState {
    checked: boolean
    value: string
}

export enum ReportInclusion {
    INCLUDE = 'include',
    EXCLUDE = 'exclude',
}

/****<< Styled Components Custom Props >>****/

export interface CheckboxLabelProps {
    disabled: boolean
}
