import styled from '@emotion/styled'
import { Chip as MuiChip } from '@mui/material'
import { HighlightOffOutlined as MuiDeleteIcon } from '@mui/icons-material'
import { StyledChipProps, StyledDeleteIconProps } from './Tag.d'

export const Chip = styled(MuiChip, { shouldForwardProp: (props) => props !== 'tagColor' })<StyledChipProps>`
    background-color: unset;
    border-radius: 7px;
    border: 1px solid ${({ tagColor }) => tagColor};
    color: ${({ tagColor }) => tagColor};
    height: 2em;
    padding: 5px;
    margin-right: 10px;
`

export const DeleteSVGIcon = styled(MuiDeleteIcon, { shouldForwardProp: (props) => props !== 'tagColor' })<StyledDeleteIconProps>`
    color: ${({ tagColor }) => tagColor} !important;
    height: 0.7em;
`
