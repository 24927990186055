import styled from '@emotion/styled'
import { Link as RouterLink } from 'react-router-dom'

export const BreadcrumbWrapper = styled.div`
    padding: 0px 0;

    .MuiBreadcrumbs-root {
        font-size: 0.9rem;
    }

    li {
        list-style-type: none;
    }
`

export const Link = styled(RouterLink)`
    text-decoration: none;
    color: #005eb8;
`
