import { GridColDef, GridValidRowModel } from '@mui/x-data-grid'

export interface TableTypeProps {
    loading?: boolean
    rowSelectable?: boolean
    checkboxSelection?: boolean
    disableSelectionOnClick?: boolean
    getRowId: (row: GridValidRowModel) => string | number
    columns: GridColDef[]
    rows: { [key: string]: any }[]
    pageSize: number
    pageNumber?: number
    rowCount: number
    onPageChange: (page: number) => void
    onPageSizePage: (pageSize: number) => void
    onSelectionChange?: (updatedSelectedIds: numbers[]) => void
    paginationType: PaginationType
}

export enum PaginationType {
    SERVER = 'server',
    CLIENT = 'client',
}
