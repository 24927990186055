import styled from '@emotion/styled'
import { MaterialDesignContent } from 'notistack'

export const Notifications = styled(MaterialDesignContent)`
    &.notistack-MuiContent-success {
        background-color: #f2f9f1;
        border: 1px solid #00854066;
        border-left: 3px solid #00853f;
        color: #1a1a1a;
        & svg {
            color: #008540;
        }
    }

    &.notistack-MuiContent-error {
        background-color: #fcf2f2;
        border: 1px solid #c6000066;
        border-left: 3px solid #c60000;
        color: #1a1a1a;
        & svg {
            color: #c60000;
        }
    }

    &.notistack-MuiContent-info {
        background-color: #f4f9fb;
        border: 1px solid #1a7ead66;
        border-left: 3px solid #1a7ead;
        color: #1a1a1a;
        & svg {
            color: #1a7ead;
        }
    }

    &.notistack-MuiContent-warning {
        background-color: #fffbf4;
        border: 1px solid #f7a82766;
        border-left: 3px solid #f7a827;
        color: #1a1a1a;
        & svg {
            color: #f7a827;
        }
    }

    &.notistack-MuiContent-default {
        background-color: #ffffff;
        border: 1px solid #999;
        border-left: 3px solid #4d4d4d;
        color: #1a1a1a;
        & svg {
            color: #1a1a1a;
        }
    }
`
