import React, { useEffect, useState } from 'react'
import { Popover } from '@mui/material'
import Tag from 'atoms/common/Tag'
// Types
import { TagData, TagGroupData } from 'types/account.d'
import { TagGroupProps } from './TagGroup.d'
// Styles
import * as Styled from './TagGroup.styled'

const TagGroup = ({ color, tagGroup, onUpdateTagGroup }: TagGroupProps) => {
    const initialState: TagGroupData = tagGroup || { name: '', tags: [] }
    const [tagGroupState, setTagGroupState] = useState(initialState)
    const [tagName, setTagName] = useState<string>('')
    const [isInvalidGroupName, setIsInvalidGroupName] = useState<boolean>(false)
    const [isInvalidTagName, setIsInvalidTagName] = useState<boolean>(false)
    const [anchorTagEl, setAnchorTagEl] = React.useState<HTMLButtonElement | null>(null)

    useEffect(() => {
        checkGroupValidation()
        onUpdateTagGroup(tagGroupState)
    }, [tagGroupState])

    useEffect(() => {
        const isInvalidName = !!tagName && !tagName.match(/^[a-zA-Z0-9]+([ _-][a-zA-Z0-9]+)*$/)
        setIsInvalidTagName(isInvalidName)
    }, [tagName])

    const checkGroupValidation = () => {
        setIsInvalidGroupName(!tagGroupState.name && !!tagGroupState.tags?.length)
    }

    const onOpenTagPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorTagEl(event.currentTarget)
    }

    const onCloseTagPopover = () => {
        setAnchorTagEl(null)
        setTagName('')
        setIsInvalidTagName(false)
    }

    const onTagGroupNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const text = evt.target.value
        setTagGroupState((state) => ({ ...state, name: text }))
    }

    const onTagNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setTagName(evt.target.value)
    }

    const onAddTag = () => {
        if (!isInvalidTagName) {
            setTagGroupState((state) => ({ ...state, tags: [...state.tags, { name: tagName }] }))
            setTagName('')
            onCloseTagPopover()
        }
    }

    const deleteTag = (index: number) => {
        const filteredTags = tagGroupState.tags.filter((_, i) => i !== index)
        setTagGroupState((state) => ({ ...state, tags: filteredTags }))
    }

    return (
        <>
            <Styled.TagGroupWrapper>
                <Styled.ColoredIndicator color={color} />
                <Styled.TextField
                    variant="outlined"
                    placeholder="Label Name"
                    value={tagGroupState.name}
                    onChange={onTagGroupNameChange}
                    error={isInvalidGroupName}
                    helperText={isInvalidGroupName && "Label name can't be empty. Assigned tags will be removed."}
                />
                {tagGroupState.tags?.map((tag: TagData, i) => (
                    <Tag name={tag.name} key={tag.id || i} color={color} onDelete={() => deleteTag(i)} />
                ))}
                <Styled.AddIconButton disabled={!tagGroupState.name} onClick={onOpenTagPopover} title="Add a new tag">
                    <Styled.AddSVGIcon disabled={!tagGroupState.name} />
                </Styled.AddIconButton>
            </Styled.TagGroupWrapper>

            <Popover
                open={!!anchorTagEl}
                anchorEl={anchorTagEl}
                onClose={onCloseTagPopover}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <Styled.PopoverContent>
                    <Styled.AddTagSection>
                        <Styled.TextField variant="outlined" placeholder="Tag Name" onChange={onTagNameChange} />
                        <Styled.Button variant="contained" disabled={!tagName || isInvalidTagName} onClick={onAddTag}>
                            Add
                        </Styled.Button>
                    </Styled.AddTagSection>
                    {isInvalidTagName && <Styled.ErrorMessage>Tag name has invalid characters.</Styled.ErrorMessage>}
                </Styled.PopoverContent>
            </Popover>
        </>
    )
}

export default TagGroup
