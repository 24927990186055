import React from 'react'
import { CircularProgress } from '@mui/material'
import * as Styled from './LoadingSpinner.styled'

export default function LoadingSpinner() {
    return (
        <Styled.LoadingSpinnerWrapper>
            <CircularProgress />
        </Styled.LoadingSpinnerWrapper>
    )
}
