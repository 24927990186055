import React, { useState, FC, useEffect } from 'react'
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid'
//Components
import Table from 'molecules/common/Table'
import Switch from 'atoms/common/Switch'
import ActivationStatus from 'atoms/common/ActivationStatus/ActivationStatus'
import PropertyTagging from 'molecules/ProperyTagging'
// Helpers
import { initialPagination } from 'pages/AccountList/AccountList.helper'
import { tagColors } from 'helpers/tag/tag'
// Types
import { PaginationProps } from 'pages/AccountList//AccountList.d'
import { AccountChildrenTableProps } from './AccountChildrenTable.d'
import { PaginationType } from 'molecules/common/Table/Table.d'
import { ChildRelation, TagData } from 'types/account.d'
// Styles
import * as Styled from './AccountChildrenTable.styled'

const AccountChildrenTable: FC<AccountChildrenTableProps> = ({ formikProps, onSelectionChange, childRelations }) => {
    const { values, handleChange } = formikProps
    const [paginationData, setPaginationData] = useState<PaginationProps>(initialPagination)
    const [data, setData] = useState<ChildRelation[]>([])

    useEffect(() => {
        setData(childRelations)
        setPaginationData(initialPagination)
    }, [childRelations])

    const onPageChange = (pageNumber: number) => {
        setPaginationData({ ...paginationData, pageNumber: pageNumber + 1 })
    }

    const onPageSizeChange = (pageSize: number) => {
        setPaginationData({ ...paginationData, pageNumber: 1, rowsPerPage: pageSize })
    }

    const propertyColumns: GridColDef[] = [
        {
            field: 'row.account.name',
            headerName: 'Property Name',
            flex: 1,
            renderCell: (params) => (
                <Styled.Link to={`/account/${params.row.account.bid}`}>{params.row.account.name}</Styled.Link>
            ),
        },
        {
            field: 'row.account.id',
            headerName: 'BID',
            flex: 1,
            renderCell: (params) => params.row.account.bid,
        },
        {
            field: 'row.account.reportingStatus',
            headerName: 'Individual Reporting',
            flex: 1.5,
            renderCell: (params) => <ActivationStatus status={params.row.account.reportingStatus} />,
        },
        {
            field: 'reportingIncluded',
            headerName: 'Include for Reporting',
            flex: 1.5,
            renderCell: (params) => {
                const accountIndex = values.childRelations.findIndex(
                    (child: ChildRelation) => child.account.bid === params.row.account.bid,
                )

                return (
                    <Switch
                        defaultValue={params.row.included}
                        name={`childRelations[${accountIndex}]['included']`}
                        handleChange={handleChange}
                    />
                )
            },
        },
        {
            field: 'tags',
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <div>
                        {'Tags '}
                        {values.tagGroups?.map((tag: TagData, index: number) => {
                            return (
                                <Styled.HeaderChip key={tag.name} customColor={tagColors[index]}>
                                    {tag.name}
                                </Styled.HeaderChip>
                            )
                        })}
                    </div>
                )
            },
            flex: 2,
            renderCell: (params) => {
                const accountIndex = values?.childRelations?.findIndex(
                    (child: ChildRelation) => child.account.bid === params.row.account.bid,
                )

                return (
                    <PropertyTagging
                        formikProps={formikProps}
                        tagGroups={values?.tagGroups}
                        selectedAccountIndex={accountIndex}
                    />
                )
            },
        },
    ]

    return (
        <Styled.AccountPropertiesDataGridWrapper>
            <Table
                checkboxSelection
                disableSelectionOnClick
                rowSelectable
                onSelectionChange={onSelectionChange}
                loading={false}
                columns={propertyColumns}
                pageSize={paginationData.rowsPerPage}
                rows={data}
                rowCount={data.length}
                onPageChange={onPageChange}
                onPageSizePage={onPageSizeChange}
                getRowId={(row) => row['account']['bid']}
                paginationType={PaginationType.CLIENT}
            />
        </Styled.AccountPropertiesDataGridWrapper>
    )
}

export default AccountChildrenTable
