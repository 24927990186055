import React, { FC } from 'react'
import { Routes, Route } from 'react-router-dom'

import ErrorPage from 'pages/ErrorPage'
import AccountList from 'pages/AccountList'
import AccountDetails from 'pages/AccountDetails/AccountDetails'
import AppLayout from '../layout'

const AppRoutes: FC = () => {
    return (
        <Routes>
            <Route>
                <Route element={<AppLayout />}>
                    <Route path="/" element={<AccountList />} />
                    <Route path="/account/:accountId" element={<AccountDetails />} />
                </Route>

                <Route path="/error" element={<ErrorPage />} />
                <Route path="*" element={<div>WRONG DIRECTION</div>} />
            </Route>
        </Routes>
    )
}

export default AppRoutes
