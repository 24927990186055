import React, { FC, useState } from 'react'
// MUI Components
import { IconButton, InputLabel, MenuItem } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
// Types
import { SelectChangeEvent } from '@mui/material/Select'
import { SelectWithClearProps } from './SelectWithClear.d'
// Styles
import * as Styled from './SelectWithClear.styled'

const SelectFilter: FC<SelectWithClearProps> = ({ label, options, onSelectChanged }) => {
    const [selectedOption, setSelectedOption] = useState('')

    const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value as string
        setSelectedOption(value)
        onSelectChanged(value)
    }

    const clearSelection = () => {
        setSelectedOption('')
        onSelectChanged('')
    }

    return (
        <Styled.FormControlWrapper>
            <InputLabel>{label}</InputLabel>
            <Styled.SelectWithClear
                label={label}
                value={selectedOption}
                onChange={handleSelectChange}
                endAdornment={
                    selectedOption && (
                        <IconButton onClick={clearSelection}>
                            <ClearIcon />
                        </IconButton>
                    )
                }
            >
                {options.map((option) => {
                    return (
                        <MenuItem key={option.value} value={option.value}>
                            {option.component}
                        </MenuItem>
                    )
                })}
            </Styled.SelectWithClear>
        </Styled.FormControlWrapper>
    )
}

export default SelectFilter
