import styled from '@emotion/styled'
import { FormControl, Select } from '@mui/material'
import { SelectWithClearType } from './SelectWithClear.d'

export const FormControlWrapper = styled(FormControl)`
    width: 100%;
`

export const SelectWithClear = styled(Select)<SelectWithClearType>`
    ${(props) =>
        props.value &&
        `
        &.MuiInputBase-adornedEnd {
            padding-right: 6px;
        }
        & .MuiSelect-iconOutlined {
            display: none;
        }
    `}
`
